// 
// calendar.scss
//

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border {
  .modal-footer {
      border-top: none;
  }
}

.fc-toolbar {
  margin: 15px 0 5px 0;
  h2 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      text-transform: uppercase;
  }
}

.fc-day-grid-event {
  .fc-time {
      font-weight: $font-weight-bold;
  }
}

.fc-day {
  background: $white;
}

.fc-toolbar {
  .fc-state-active,
  .ui-state-active,
  button:focus,
  button:hover,
  .ui-state-hover {
      z-index: 0;
  }
}

.fc {
  th.fc-widget-header {
      background: $gray-100;
      font-size: 13px;
      line-height: 20px;
      padding: 10px 0;
      text-transform: uppercase;
  }
}

.fc-unthemed {
  th,
  td,
  thead,
  tbody,
  .fc-divider,
  .fc-row,
  .fc-popover {
      border-color: $gray-300;
  }
}

.fc-ltr {
  .fc-basic-view {
      .fc-day-top {
          .fc-day-number {
              float: right;
              margin: 5px;
              font-family: $font-family-secondary;
              font-size: 12px;
          }
      }
  }
}

.fc-button {
  background: $gray-100;
  border: none;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: $gray-100;
}

.fc-state-highlight {
  background: $gray-300;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background: $bg-gradient;
  color: $white;
  text-shadow: none;
}

.fc-cell-overlay {
  background: $gray-300;
}

.fc-unthemed {
  .fc-today {
      background: $white;
  }
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: $white;
}

.fc-basic-view {
  td.fc-week-number {
      span {
          padding-right: 8px;
      }
  }
  td.fc-day-number {
      padding-right: 8px;
  }
  .fc-content {
      color: $white;
  }
}

.fc-time-grid-event {
  .fc-content {
      color: $white;
  }
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
      .fc-left,.fc-right,.fc-center {
          float: none;
          display: block;
          clear: both;
          margin: 10px 0;
      }
  }
  .fc {
      .fc-toolbar{
          >* {
              >* {
                  float: none;
              }
          }
      }
  }
  .fc-today-button {
      display: none;
  }
}