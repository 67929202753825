// 
// menu.scss
//

// Metis Menu Overwrite

.metismenu {
    padding: 0;

    li {
        list-style: none;
    }
    ul {
        padding: 0;
        li {
            width: 100%;
        }
    }

    .mm-collapse:not(.mm-show) {
        display: none;
    }
    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}

.nav-second-level,
.nav-thrid-level {
    li {
        a {
            padding: 8px 20px;
            color: $menu-item;
            display: block;
            position: relative;
            transition: all 0.4s;
            &:focus,
            &:hover {
                color: $menu-item-hover;
            }
        }
    }
}

.nav-second-level,
.nav-third-level {
    li.mm-active {
        >a {
            color: $menu-item-active;
        }
    }
}

// Wrapper
#wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: hidden;
    padding: 0 15px 5px 15px;
    min-height: 80vh;
    margin-top: $topbar-height;
}

// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    background: $bg-leftbar-light;
    bottom: 0;
    padding: 20px 0;
    position: fixed;
    transition: all .2s ease-out;
    top: $topbar-height;
    box-shadow: $box-shadow;
    z-index: 10;
}

// Sidebar
#sidebar-menu {
    >ul {
        >li {
            >a {
                color: $menu-item;
                display: block;
                padding: 13px 20px;
                position: relative;
                transition: all 0.4s;
                font-family: $font-family-secondary;
                font-size: 15px;

                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-hover;
                    text-decoration: none;
                    background: $light;
                }
                >span {
                    vertical-align: middle;
                }

                i {
                    display: inline-block;
                    line-height: 1.0625rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                    font-size: 16px;
                }
                .drop-arrow {
                    float: right;
                    i {
                        margin-right: 0;
                    }
                }
            }
            > a.active {
                color: $menu-item-active;
                background: $light;
            }

            > ul {
                padding-left: 37px;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }
    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        transform: translate(0, 0);
        &:before {
            content: "\F142";
        }
    }
    .badge{
        margin-top: 4px;
    }

    li.mm-active {
        > a {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }

    .menu-title {
        padding: 10px 20px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $menu-item;
        font-weight: $font-weight-semibold;
    }
}

// Enlarge menu
.enlarged {

    .logo-box {
        width: $leftbar-width-collapsed !important;
    }

    .logo {
        span.logo-lg {
            display: none;
        }
        span.logo-sm {
            display: block;
        }
    }
    // Side menu
    .left-side-menu {
        position: absolute;
        padding-top: 0;
        width: $leftbar-width-collapsed !important;
        z-index: 5;

        .slimScrollDiv,
        .slimscroll-menu {
            overflow: inherit !important;
            height: auto !important;
        }
        .slimScrollBar {
            visibility: hidden;
        }

        // Sidebar Menu
        #sidebar-menu {
            .menu-title,
            .menu-arrow,
            .label,
            .badge{
                display: none !important;
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px 20px;
                        min-height: 54px;
                        transition: none;
            
                        &:hover,
                        &:active,
                        &:focus {
                            color: $menu-item-hover;
                        }
                        i {
                            font-size: 1.125rem;
                            margin-right: 20px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }
                    }
                    
                    &:hover  {
                        > a {
                            position: relative;
                            width: calc(190px + #{$leftbar-width-collapsed});
                            color: $menu-item-active;
                            background-color: $light;
                            transition: none;

                            span {
                                display: inline;
                            }
                        }

                        a.open,a.mm-active {
                            :after {
                                display: none;
                            }
                        }

                        > ul {
                            display: block;
                            left: $leftbar-width-collapsed;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: 3px 5px 10px 0 rgba(154,161,171,.2);

                            ul {
                                box-shadow: 3px 5px 10px 0 rgba(154,161,171,.2);
                            }
                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;
                                &:hover {
                                    color: $menu-item-hover;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar-light;

                    li {
                        &:hover {
                            > ul {
                                display: block;
                                left: 190px;
                                margin-top: -36px;
                                height: auto !important;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        > a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }
                    li.active {
                        a {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }

    // Content Page
    .content-page {
        margin-left: $leftbar-width-collapsed !important;
    }

    //Footer
    .footer {
        left: $leftbar-width-collapsed !important;
    }

    //User box
    .user-box {
        display: none;
    }
}

// Body min-height set
body.enlarged {
    min-height: 1200px;
}

@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
        padding-bottom: 80px;
    }
    .left-side-menu {
        display: none;
        z-index: 999 !important;
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }
    .content-page,.enlarged .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }
    .pro-user-name {
        display: none;
    }
    .logo-box {
        display: none;
    }
}